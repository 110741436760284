/* * * * * * * * * *
+320px :: 20em
+480px :: 30em
+600px :: 37.50em
+768px :: 48em
+992px :: 62em
+1024px :: 64em
+1140px :: 71.250em
+1224px :: 76.5em
+1382px :: 86.375em
* * * * * * * * * */
/* Media Queries*/
/* Phones */
/* Tablets*/
/* Tablets Horizontal*/
/* Desktops, slightly less large devices */
/* Desktops, large devices */
/* Desktops, very large devices */
/* -----------------------------
   FUNCTIONS
   ----------------------------- */
/*
Removes the unit (e.g. px, em, rem) from a value, returning the number only.
@param {Number} $num - Number to strip unit from.
@returns {Number} The same number, sans unit.
*/
/*
Converts one or more pixel values into matching rem values.
@param {Number|List} $values - One or more values to convert. Be sure to separate them with spaces and not commas. If you need to convert a comma-separated list, wrap the list in parentheses.

@param {Number} $base [null] - The base value to use when calculating the `rem`. If you're using Foundation out of the box, this is 16px. If this parameter is `null`, the function will reference the `$base-font-size` variable as the base.

@returns {List} A list of converted values.
*/
/*
Converts a pixel value to matching rem value. *Any* value passed, regardless of unit, is assumed to be a pixel value. By default, the base pixel value used to calculate the rem value is taken from the `$global-font-size` variable.
@access private
@param {Number} $value - Pixel value to convert.
@param {Number} $base [null] - Base for pixel conversion.
@returns {Number} A number in rems, calculated based on the given value and the base pixel value. rem values are passed through as is.
*/
@media screen and (min-width: 48em) {
  .bloc-contenu-lien-plus-poupon,
  .bloc-contenu-lien-plus-accifamille,
  .bloc-contenu-lien-plus-acci7plus,
  .bloc-contenu-lien-multiterme,
  .bloc-contenu-lien-assurance-pret-universelle {
    padding-top: 11px;
  }
}

.bloc-contenu.quebec-uniquement {
  background-color: #e3f2fd;
  border: 1px solid #3a75d9;
  border-radius: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.bloc-contenu.quebec-uniquement .icone-bloc-contenu {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.bloc-contenu.quebec-uniquement .icone-bloc-contenu .material-icons {
  font-size: 56px;
  color: #1f62d4;
}

section {
  position: relative;
}

#grille-zone-formulaire {
  background-position: top center;
}
@media screen and (max-width: 1139px) {
  #grille-zone-formulaire {
    background-size: 1200px auto;
  }
}
@media screen and (min-width: 71.25em) {
  #grille-zone-formulaire {
    background-attachment: fixed;
  }
}

#formulaire-bambin .background-flou {
  background-image: url(/-/media/Files/IA/poupon-bambin/fonds/bambin_Adherer-flou.jpg);
}

#formulaire-poupon .background-flou {
  background-image: url(/-/media/Files/IA/poupon-bambin/fonds/poupon_Adherer-flou.jpg);
}

.contenu-liste-enfants .section-enfant {
  margin-bottom: 16px;
}

.zone-btn-ajout {
  margin-top: 20px;
}

.champ-autre-adresse {
  display: inline-block;
}

/*--------------------------------Pages statiques--------------------------*/
.grille-hero-accueil-poupon-bambin .hero-separateur {
  display: none;
}
.grille-hero-accueil-poupon-bambin.heroV2 .zone-hero-texte .hero-sous-titre {
  max-width: 475px;
  margin-top: 16px;
  font-weight: 700;
}

#grille-zone-pourquoi-adherer #grille-contenu {
  padding-bottom: 300px;
  border: solid 1px #759bc6;
  background-image: url("/-/media/Files/IA/poupon-bambin/custom/Poupon_jouet_Custom.jpeg");
  background-size: auto 300px;
  background-position: center bottom;
  background-repeat: no-repeat;
}
@media screen and (min-width: 62.5em) {
  #grille-zone-pourquoi-adherer #grille-contenu {
    padding-bottom: 48px;
    background-size: 100% auto;
    background-position: calc(50% + 350px) bottom;
  }
}
@media screen and (min-width: 62.5em) {
  #grille-zone-pourquoi-adherer #grille-contenu .bloc-contenu-liste {
    max-width: 66.66%;
  }
}

#grille-zone-pourquoi-adherer-bambin #grille-contenu {
  background-size: 1000px auto;
  background-position: right -340px bottom 0px;
  background-image: url(/-/media/Files/IA/poupon-bambin/custom/Bambin_Kit_Custom.jpg);
  background-repeat: no-repeat;
  background-color: #eff1f4;
}
@media screen and (max-width: 1139px) {
  #grille-zone-pourquoi-adherer-bambin #grille-contenu {
    background-size: 1200px auto;
    background-position: right -500px bottom;
  }
}
@media screen and (max-width: 999px) {
  #grille-zone-pourquoi-adherer-bambin #grille-contenu {
    padding-bottom: 304px;
    background-position: center bottom;
  }
}
#grille-zone-pourquoi-adherer-bambin #grille-contenu #grille-zone-avantage {
  background: white;
}
@media screen and (min-width: 62.5em) {
  #grille-zone-pourquoi-adherer-bambin #grille-contenu .bloc-contenu-liste {
    max-width: 66.66%;
  }
}

#grille-zone-avantage {
  background: #e3f2fd;
}

#grille-zone-decouvrez-programme #grille-contenant {
  background-size: auto 300px;
  background-position: center top;
  background-repeat: no-repeat;
  background-image: url(/-/media/Files/IA/poupon-bambin/custom/Bandeau_Bambin.jpg);
  padding-top: 348px;
}
@media screen and (min-width: 62.5em) {
  #grille-zone-decouvrez-programme #grille-contenant {
    padding-top: 48px;
    background-size: 1366px auto;
    background-position: right -353px top;
  }
  #grille-zone-decouvrez-programme #grille-contenant .bloc-contenu p {
    max-width: 540px;
  }
  #grille-zone-decouvrez-programme #grille-contenant .btn {
    min-width: 300px;
  }
}

#grille-zone-decouvrez-programme-bambin #grille-contenant {
  background-size: auto 300px;
  background-position: center top;
  background-repeat: no-repeat;
  background-image: url(/-/media/Files/IA/poupon-bambin/custom/Bandeau_Poupon.jpg);
  padding-top: 348px;
}
@media screen and (min-width: 62.5em) {
  #grille-zone-decouvrez-programme-bambin #grille-contenant {
    padding-top: 48px;
    background-size: 1366px auto;
    background-position: right -353px top;
  }
  #grille-zone-decouvrez-programme-bambin #grille-contenant .bloc-contenu p {
    max-width: 540px;
  }
  #grille-zone-decouvrez-programme-bambin #grille-contenant .btn {
    min-width: 300px;
  }
}

.icone-supprimer {
  fill: #2062d4;
  width: 24px;
  height: 24px;
  margin: -6px 5px -6px -24px;
}

@media screen and (min-width: 62.5em) {
  #grille-zone-avantage {
    width: 100%;
  }
  #grille-zone-avantage .col-med-1-2.deux-Colonnes-Gauche {
    width: 45% !important;
  }
  #grille-zone-avantage .col-med-1-2.deux-Colonnes-Droite {
    width: 52% !important;
  }
}
#grille-zone-avantage .icone-flottant-bleu {
  padding-right: 10px;
  padding-left: 68px;
  min-height: 103px;
}
#grille-zone-avantage .icone-flottant-bleu svg, #grille-zone-avantage .icone-flottant-bleu .icone-bloc-contenu {
  width: 36px;
  height: 36px;
}
@media screen and (max-width: 767px) {
  #grille-zone-avantage .icone-flottant-bleu {
    padding: 16px;
  }
}
@media screen and (max-width: 999px) {
  #grille-zone-avantage.grilleTroixColonnes {
    padding: 8px 0;
  }
  #grille-zone-avantage.grilleTroixColonnes > * {
    width: 100%;
    padding: 0;
    margin: 0;
  }
  #grille-zone-avantage.grilleTroixColonnes .icone-flottant-bleu {
    padding-right: 10px;
    padding-left: 68px;
    min-height: 55px;
    text-align: left;
  }
  #grille-zone-avantage.grilleTroixColonnes .icone-flottant-bleu .icone-bloc-contenu {
    position: absolute;
    left: 16px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  #grille-zone-avantage.grilleTroixColonnes .icone-flottant-bleu p {
    margin: 0;
  }
}
#grille-zone-avantage .copy-footer-nux {
  color: #6b6b6b;
}
#grille-zone-avantage .header-transactionnel .btn-langue-header {
  display: none;
}
@media screen and (max-width: 1139px) {
  #grille-zone-avantage .commun-fond-bleu-ia-trans-80 {
    background-color: rgb(0, 61, 165) !important;
  }
  #grille-zone-avantage #grille-zone-service-clientele .bloc-bta-wrapper {
    max-width: none;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}
#grille-zone-avantage table.commun-tableau-responsive-dropdown tr th,
#grille-zone-avantage table.commun-tableau-responsive-dropdown tr td {
  padding: 16px;
}
.page-assurance-hypothecaire #grille-zone-avantage table.commun-tableau-responsive-dropdown tr th,
.page-assurance-hypothecaire #grille-zone-avantage table.commun-tableau-responsive-dropdown tr td {
  width: 33%;
}